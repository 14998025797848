.container.contact_class{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact_option{
background-color: var(--color-bg-variant);
padding: 1.2rem;
border-radius: 1.2rem;
text-align: center;
border: 1px solid transparent;
transition: var(--transition);
}
.contact_option:hover{
    background: var(--color-primary-variant);
}
.contact_icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
.contact_option h5{
    color: var(--color-light);
}
/* form */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
   
}
input,textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
background: transparent;
border: 2px solid var(--color-primary-variant);
resize: none;
color: var(--color-white);
font-weight: bold;
font-size: 0.9rem;
}

/*  MEDIA QURIES  */
/*-----MEDIA QURIES(Tablates)-----*/
@media  screen and (max-width:1024px){
  .container.contact_class{
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  #contact{
  margin-bottom: 30vh;
  }
}
/*-----MEDIA QURIES(phones)-----*/
@media  screen and (max-width:600px){
   .container.contact_class{
    width: var(--container-width-sm);
   }
   #contact{
    margin-bottom: 80vh;
    }
}