.portfolio_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  position: relative;
  height: 100%;
  
}

.portfolio_item {
  position: relative;
  text-align: center;
  border-radius: 2rem;
  border: 2px solid var(--color-primary-variant);
  color: var(--color-light);
  box-shadow: 2px 2px 5px 2px var(--color-primary-variant);
  overflow: hidden;
  height: 400px;
  max-height: 300px; /* Set a max height for consistency */
}

.portfolio_item-image {
  top: 10px;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  height: 200px; /* Set a fixed height for images */
}

.portfolio_item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;  

}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.h3_prot {
  font-size: 1.2rem;
  margin: 1rem 0;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
 
}

.p_prot {
  font-size: 1rem;
  overflow: auto;
  max-height: 100px; /* Limit the height of the text */
}

.hold {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem; /* Add space between elements */
}

/* -----MEDIA QUERIES (Tablets)----- */
@media screen and (max-width: 1024px) {
  .portfolio_container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  .information {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

/* -----MEDIA QUERIES (Phones)----- */


/*-----MEDIA QURIES(phones)-----*/
@media  screen and (max-width:600px) {
  #portfolio{
   height: max-content;
   margin-bottom: 0;
  }
   .portfolio_container{
     display: grid;
     grid-template-columns: 3fr ;
     gap: 1rem;
     align-items: center;
 justify-content: center;   
 position: relative; 
 }
 
 }