header{
    height: 100vh ;
    padding-top: 1rem;
    /* padding-top: 7rem; */

    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}
/*------CTA-----*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content:center ;
}
/*------socials----*/
.header__social{
    display: flex;
    flex-direction: column;
    align-items: center; 
    /* justify-content: center; */
    gap: 0.8rem;
    position: absolute;
}
.header__social::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
.me{
    background: linear-gradient(var(--color-primary),transparent);
    width: 20rem;
    /* width: 22rem; */
    height: 28rem;
    /* height: 30rem; */
    position:absolute;
    left: calc(50% - 10rem);
    margin-top: 2rem;
    overflow: hidden;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/*------scroll down----*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 10rem;
    /* bottom: 5rem; */

    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9 rem;
}
.theme{
    background-color: transparent;
    
}
.theme:hover{
    background: var(--color-hover);
    color: var(--color-bg);
    border-color: transparent;
}
/*-----MEDIA QURIES(Tablates)-----*/
@media  screen and (max-width:1024px){
    header{
        height: 68vh;
    }
}
/*-----MEDIA QURIES(phones)-----*/
@media  screen and (max-width:600px) {
    header{
        height: 100vh;
    }
    .header__social,
    .scroll__down{
        display: none;
    }
}